import { appTypeConfig, catalogExtensionsRules, R, L } from './config'

// forbiden combination: FC
// only possible combination: OPC
const {
  FORBIDDEN_COMBINATION,
  ONLY_POSSIBLE_COMBINATION,
  MANDATORY_COMBINATION,
  EXPRESS_DISABLED,
  FRAME_TYPE_DISABLED,
} = catalogExtensionsRules

const COMBINATION_EXTENSIONS = [
  FORBIDDEN_COMBINATION,
  ONLY_POSSIBLE_COMBINATION,
  MANDATORY_COMBINATION,
]

const log = console.getLogger('CATALOG')

const filterExtensions = (catalogExtensions, rule) =>
  catalogExtensions.filter(extension => extension?.rule === rule)

const getOptionCode = ({ id, options }) =>
  options?.options.find(({ _id }) => _id === id)?.originalId

const getExtension = ({ catalogExtensions, coatingCode, validRules }) =>
  catalogExtensions.find(extension => {
    if (validRules && !validRules.includes(extension.rule)) return false
    return extension?.coatingCode === coatingCode
  })

// funkce: pokud je vybraný coating, tak disablovat zavrhnuté barvy
// pokud na coating existuje OPC - tak zobrazit jen ty možné
export const filterColors = ({
  catalogExtensions,
  colorOptions,
  coatingOptions,
  selectedCoating,
}) => {
  const coatingCode = getOptionCode({ id: selectedCoating, options: coatingOptions })

  const extension = getExtension({
    catalogExtensions,
    coatingCode,
    validRules: COMBINATION_EXTENSIONS,
  })

  if (!extension) return colorOptions

  if (extension.rule === FORBIDDEN_COMBINATION) {
    // vyhodit nevyhovující
    const newColorOptions = {
      ...colorOptions,
      selectOptions: colorOptions.selectOptions.filter(
        option => !extension.colorCodes.includes(option.code),
      ),
    }
    // const newColorOptions = {
    //   ...colorOptions,
    //   selectOptions: colorOptions.selectOptions.map(option => ({
    //     ...option,
    //     isDisabled: extension.colorCodes.includes(option.code),
    //   })),
    // }
    return newColorOptions
  }

  if (extension.rule === ONLY_POSSIBLE_COMBINATION || extension.rule === MANDATORY_COMBINATION) {
    const newColorOptions = {
      ...colorOptions,
      selectOptions: colorOptions.selectOptions.filter(option =>
        extension.colorCodes.includes(option.code),
      ),
    }
    return newColorOptions
  }

  return colorOptions
}

export const isCombinationValid = ({ coatingCode, colorCode, catalogExtensions }) => {
  const extension = getExtension({
    catalogExtensions,
    coatingCode,
    validRules: COMBINATION_EXTENSIONS,
  })

  if (!extension) return true

  if (extension.rule === FORBIDDEN_COMBINATION) {
    return !extension.colorCodes.includes(colorCode)
  }
  if (extension.rule === ONLY_POSSIBLE_COMBINATION || extension.rule === MANDATORY_COMBINATION) {
    return extension.colorCodes.includes(colorCode)
  }
  return true
}

export const getIsColorRequiredWithCoating = ({ coatingId, coatingOptions, catalogExtensions }) => {
  const coatingCode = getOptionCode({ id: coatingId, options: coatingOptions })

  const extension = getExtension({
    catalogExtensions,
    coatingCode,
    validRules: [MANDATORY_COMBINATION],
  })

  return !!extension
}

export const isColorAvailableWithNewCoating = ({
  coatingId,
  catalogExtensions,
  coatingOptions,
  colorOptions,
  colorId,
}) => {
  // povinná barva se validuje, takže stačí jen smazat. Není třeba přiřazovat novou barvu
  const coatingCode = getOptionCode({ id: coatingId, options: coatingOptions })
  const colorCode = getOptionCode({ id: colorId, options: colorOptions })

  return isCombinationValid({ coatingCode, colorCode, catalogExtensions })
}

export const isExpressDisabled = ({ coatingCode, catalogExtensions }) => {
  const extension = getExtension({ catalogExtensions, coatingCode, validRules: [EXPRESS_DISABLED] })

  if (!extension) return false
  const isDisabled = extension.rule === EXPRESS_DISABLED

  return isDisabled
}

export const isExpressDisabledWithCoating = ({ coatingId, catalogExtensions, coatingOptions }) => {
  const coatingCode = getOptionCode({ id: coatingId, options: coatingOptions })

  return isExpressDisabled({ coatingCode, catalogExtensions })
}

const compareGroupNames = (name1, name2) => name1?.toLowerCase() === name2?.toLowerCase()

const getOptionsFromLens = (lens, selectType) =>
  lens.optionsArray.find(({ selectType: type }) => type === selectType)?.options || []

const getIsLensInCompatibleGroups = (lens, compatibleGroups) =>
  lens?.groups?.some(lensGroup => {
    const lensGroupName = lensGroup?.names?.[0]?.short
    return compatibleGroups?.some(compatibleGroup =>
      compareGroupNames(compatibleGroup, lensGroupName),
    )
  })

const getIsOptionCompatible = (option, compatibleGroups) =>
  option?.groups?.some(optionGroup =>
    compatibleGroups?.some(compatibleGroup => compareGroupNames(optionGroup, compatibleGroup)),
  )

// const test = lens => lens?.groups?.some(lensGroup => {
//   const lensGroupName = lensGroup?.names?.[0]?.short
//   return extension.groups.some(compatibleGroup =>
//     compareGroupNames(compatibleGroup, lensGroupName),
//   )
// })

// returns array of frame brands that are not compatible with current lens and options
export const getDisabledFrameBrands = ({
  catalogExtensions,
  currentLensR,
  currentLensL,
  orderFieldValues = {},
}) => {
  if (!catalogExtensions) return []
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_ONLY_WITH_GROUP,
  )
  // there is nothing to check, if have no specified extensions,
  // all frame brands are valid, return empty array
  if (!validExtensions.length) return []

  const frameCompatibleOptionsExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_COMPATIBLE_OPTIONS,
  )

  const {
    coatingR,
    coatingL,
    colorR,
    colorL,
    rightLensEnabled,
    leftLensEnabled,
    isLeftLensSameAsRight,
  } = orderFieldValues

  const getIsCoatingCompatible = (option, compatibleGroups, lens) => {
    // us FRAME_COMPATIBLE_OPTIONS extension if exists for lens
    const extensionForLens = frameCompatibleOptionsExtensions.find(
      extension => extension.lensCode === lens?.code,
    )
    if (extensionForLens) {
      return extensionForLens.coatingCodes.includes(option.originalId)
    }
    // fallback to FRAME_ONLY_WITH_GROUP extension
    return option?.groups?.some(optionGroup =>
      compatibleGroups.some(compatibleGroup => compareGroupNames(optionGroup, compatibleGroup)),
    )
  }
  // check compatibility of one side
  const isSideCompatible = ({ side, compatibleGroups }) => {
    const lens = side === R ? currentLensR : currentLensL
    const coating = side === R ? coatingR : coatingL
    const color = side === R ? colorR : colorL

    // is lens compatible with frameBrand
    const isLensCompatible = getIsLensInCompatibleGroups(lens, compatibleGroups)
    if (!isLensCompatible) return false

    const coatingOptions = getOptionsFromLens(lens, 'COATING')

    // if compatible coating is available, it must be selected
    // otherwise frameBrand is not compatible
    // check only if enabled in appTypeConfig
    if (
      appTypeConfig?.catalogExtensions?.FRAME_ONLY_WITH_GROUP
        ?.isCompatibleOnlyWithCoatingIfAvailable
    ) {
      if (coatingOptions?.length && !coating) {

        const extensionForLens = frameCompatibleOptionsExtensions.find(
          extension => extension.lensCode === lens?.code,
        )
        if (extensionForLens) {
          // if there are no coatings in catalog extension, it means no coating is compatible
          // and lens should be compatible without coating
          return extensionForLens.coatingCodes.length === 0
        }
        // if there is no extension for compatible options, we check compatible groups only
        const hasCompatibleCoating = coatingOptions.some(option =>
          option?.groups.some(g =>
            compatibleGroups.some(compatibleGroup => compareGroupNames(g, compatibleGroup)),
          ),
        )

        if (hasCompatibleCoating) return false
      }
    }

    if (coating) {
      const selectedCoating = coatingOptions.find(({ _id }) => _id === coating)

      const isCoatingCompatible = getIsCoatingCompatible(selectedCoating, compatibleGroups, lens)
      if (!isCoatingCompatible) return false
    }

    // we do not check color now
    // if (color) {
    //   const colorOptions = getOptionsFromLens(lens, 'COLOR')
    //   const selectedColor = colorOptions.find(({ _id }) => _id === color)
    //   const isColorCompatible = isOptionCompatible(selectedColor, compatibleGroups)
    //   if (!isColorCompatible) return false
    // }

    return true
  }

  const result = []

  validExtensions.forEach(({ frameBrand, groups }) => {
    if (rightLensEnabled) {
      if (!isSideCompatible({ side: R, compatibleGroups: groups })) return result.push(frameBrand)
    }
    if (leftLensEnabled && !isLeftLensSameAsRight) {
      if (!isSideCompatible({ side: L, compatibleGroups: groups })) return result.push(frameBrand)
    }
  })

  return result
}

export const getIsFrameValid = ({
  order,
  catalogExtensions,
  currentLensR,
  currentLensL,
  frame,
}) => {
  const frameBrand = frame?.fsupplier
  if (!frameBrand) return true

  const disabledFrameBrands = getDisabledFrameBrands({
    catalogExtensions,
    currentLensR,
    currentLensL,
    orderFieldValues: order,
  })
  return !disabledFrameBrands.includes(frameBrand)
}

// returns true if lens is compatible with selected frameBrand according to catalogExtensions
// used for filtering
export const getIsLensCompatibleWithFrameBrand = ({ lens, frameBrand, catalogExtensions }) => {
  if (!catalogExtensions) return true
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_ONLY_WITH_GROUP,
  )
  const frameBrandExtension = validExtensions.find(
    extension => extension?.frameBrand === frameBrand,
  )
  if (!frameBrandExtension) return true

  const compatibleGroups = frameBrandExtension.groups
  const isLensCompatible = getIsLensInCompatibleGroups(lens, compatibleGroups)

  // const isLensCompatible = lens?.groups?.some(lensGroup => {
  //   const lensGroupName = lensGroup?.names?.[0]?.short
  //   return compatibleGroups.some(compatibleGroup =>
  //     compareGroupNames(compatibleGroup, lensGroupName),
  //   )
  // })

  return isLensCompatible
}

// returns list of frame brands that are compatible with lens according to catalogExtensions
export const getCompatibleFrameBrands = ({ lens, option, catalogExtensions }) => {
  if (!catalogExtensions) return null

  // first check if there is FRAME_COMPATIBLE_OPTIONS extension set for current lens
  // but only for option check
  if (option) {
    const frameCompatibleOptionsExtensions = filterExtensions(
      catalogExtensions,
      catalogExtensionsRules.FRAME_COMPATIBLE_OPTIONS,
    ).filter(extension => extension.lensCode === lens?.code)

    // if we have FRAME_COMPATIBLE_OPTIONS extension assigned to this lens
    // check which frame brands are compatible with selected option
    if (frameCompatibleOptionsExtensions?.length > 0) {
      const brands = []
      frameCompatibleOptionsExtensions.forEach(extension => {
        if (extension.coatingCodes.includes(option?.code)) {
          brands.push(extension.frameBrand)
        }
      })
      return brands
    }
  }

  // if there is no FRAME_COMPATIBLE_OPTIONS extension for this lens
  // (or for lens check only)
  // fallback to FRAME_ONLY_WITH_GROUP extension
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_ONLY_WITH_GROUP,
  )
  if (!validExtensions.length) return null

  const compareFnc = option
    ? extension => getIsOptionCompatible(option, extension.groups)
    : extension => getIsLensInCompatibleGroups(lens, extension.groups)

  const filteredExtensions = validExtensions.filter(compareFnc)

  if (!filteredExtensions.length) return null
  return filteredExtensions.map(({ frameBrand }) => frameBrand)
}

export const getAllFramesWithCondition = catalogExtensions => {
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_ONLY_WITH_GROUP,
  )
  if (!validExtensions.length) return []
  return validExtensions.map(({ frameBrand }) => frameBrand)
}

export const getIsLensDisabledWithoutEvo = ({ lens, catalogExtensions }) => {
  if (!catalogExtensions) return false
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.DISABLED_WITHOUT_EVO,
  )
  if (!validExtensions.length) return false

  const isLensInValidExtension = validExtensions.some(extension => {
    const isLensInGroup = getIsLensInCompatibleGroups(lens, extension.groups)
    if (!isLensInGroup) return false

    return true
  })

  return isLensInValidExtension
}

export const getIsFrameTypeDisabled = ({
  frameType,
  lensR,
  lensL,
  catalogExtensions,
  orderFieldValues,
}) => {
  if (!catalogExtensions) return false
  const validExtensions = filterExtensions(
    catalogExtensions,
    catalogExtensionsRules.FRAME_TYPE_DISABLED,
  )
  if (!validExtensions.length) return false

  return validExtensions.some(extension => {
    if (extension.frameType !== frameType) return false

    return extension.lensIndexes.some(lensIndex => {
      if (orderFieldValues.rightLensEnabled && lensR?.materialProperty?.opticalIndex === lensIndex)
        return true
      if (orderFieldValues.leftLensEnabled && lensL?.materialProperty?.opticalIndex === lensIndex)
        return true
      return false
    })
  })
}
